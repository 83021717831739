.chipSelected {
  border: 1px solid #3081F6;
  color: #3081F6;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.centered {
  display: flex;
  flex-direction: column;
}

.chips {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 7.98px;
}

.inputBox {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border: 1px solid #C1CDE5;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 12px 16px;
}

.inputBox:hover {
  border: 1px solid #3081F6;
}

.input {
  display: flex;

  flex-direction: row;
  flex: 1;

  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 14px;
  line-height: 22px;
  font-family: e-Ukraine-Light;
}

.activeButton {
  background-color: #3081F6;
}

.chip {
  border: 1px solid #C1CDE5;
  color: #63708A;

  font-size: 12px;
  line-height: 18px;
  font-family: e-Ukraine-Regular;
  padding: 8px 14px;
  /* border: 1px solid #C1CDE5; */
  border-radius: 6px;
}

.selectedChip {
  border: 1px solid #3081F6;
  color: #3081F6;

  font-size: 12px;
  line-height: 18px;
  font-family: e-Ukraine-Regular;
  padding: 8px 14px;
  /* border: 1px solid #C1CDE5; */
  border-radius: 6px;

}

.chip:disabled {
  color: white;
	background-color: #DDE1E7;
  border: 1px solid #DDE1E7;
}
