.container {
  flex-basis: calc(25% - 32px);
  display: flex;
  flex-flow: column wrap;
  margin: 16px;
  border: 1px solid #C1CDE5;
  box-sizing: border-box;
  border-radius: 8px;
}

@media (max-width: 1800px) {
  .container {
    flex-basis: calc(33.3% - 32px);
  }
}

@media (max-width: 1280px) {
  .container {
    flex-basis: calc(50% - 32px);
  }
}

@media (max-width: 860px) {
  .container {
    flex-basis: calc(100% - 32px);
  }

  .tokens-list {
    justify-content: center;
  }
}

.padding {
  padding: 24px;
}

.head {
  margin-bottom: 32px;
  display: flex;
}

.headStatus {
  flex-grow: 1;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 84px;
  height: 24px;

  border-radius: 4px;
  font-size: 10px;

  color: #182849;
}

.enabled {
  background: #A6F8D0;
}

.disabled {
  background: #F8A6A6;
}

.row {
  display: flex;
  margin-bottom: 24px;
}

.row > strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #63708A;

  width: 100px;
  margin-right: 32px;
  font-family: 'e-Ukraine-UltraLight', 'Arial';
}

.row > div {
  font-size: 16px;
  line-height: 22px;
  color: #010E28;
  width: 128px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headIcons {
  display: flex;
  justify-content: center;
  align-items: center;
}
