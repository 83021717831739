.brand {
  font-weight: bold;
  font-size: 20px;
  display: none;

  text-decoration: none;
  color: #FFFFFF;
}

.site-header {
  position: relative;
  background-color: #3081F6;
}

.site-header__message {
  background-color: #C9E0FA;
  text-align: center;
  font-size: 20px;
  padding: 24px 24px;
}

.site-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding: 24px 24px;
}

.site-header__end__item a {
  text-decoration: none;
  color: #FFFFFF;
}

.site-header__end__item button {
  text-decoration: none;
  color: #FFFFFF;
  text-decoration: inherit;
}

.site-header__end__item:hover {
  text-decoration: underline;
}

@media (min-width: 660px) {
  .site-header__wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 659px) {
  .site-header__end {
    display: flex;
    padding-right: 3rem;
  }

  .site-header__middle {
    display: none;
  }

  .site-header__start {
    display: flex;
  }
}

@media (min-width: 660px) {
  .nav__wrapper {
    display: flex;
  }
}

@media (max-width: 659px) {
  .nav__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #3081F6;
    visibility: hidden;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }

  .nav__wrapper_dropdown.active {
    display: block;
  }
}

.nav__item a {
  display: block;
  padding: 24px 24px;

  text-decoration: none;
  color: #FFFFFF;
  text-decoration: inherit;
}


.nav__item button {
  text-decoration: none;
  color: #FFFFFF;
  text-decoration: inherit;
}

.nav__item:hover {
  text-decoration: underline;
}

.nav__toggle {
  display: none;
}

@media (max-width: 659px) {
  .nav__toggle {
    display: block;
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.site-header__dropdown {
  display: none;
}

.nav-dropdown {
  display: block;
}

.nav__wrapper_dropdown {
  display: none;
}

@media (max-width: 660px) {
  .site-header__dropdown {
    display: block;
  }
}

.nav-dropdown__item a {
  padding: 24px;

  display: flex;
  text-decoration: none;
  color: #FFFFFF;
}

.nav-dropdown__item:focus {
  text-decoration: underline;
  background-color: #6582BB;
}

.nav-dropdown__item:hover {
  text-decoration: underline;
}
