.contentWrapper {
  padding: 16px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  row-gap: 24px;
}

@media (max-width: 1400px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1000px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 750px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

.skeleton {
  border-radius: 8px;
  margin: 12px;
}

.vehicle {
  border: 1px solid #F5F8FD;
  box-shadow: 0px 0px 4px rgba(139, 156, 180, 0.18);
  padding: 24px;
  border-radius: 8px;
  color: inherit;
}

div .vehicle:hover {
  border: 1px solid #3081F6;
  color: #3081F6;
}

.vehicleLink {
  text-decoration: none;
  color: inherit;
}

.brand {
  color: inherit;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.row {
  color: #010E28;
  padding-top: 12px;
}

.row:last-of-type {
  padding-bottom: 0px;
  padding-top: 12px;
}

.rowTitle {
  color: #63708A;
  font-size: 16px;
}

.pageTitle {
  margin-top: 60px;
  margin-bottom: 40px;
}