.tabList {
  display: flex;
  margin: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  flex-direction: row;
  border-bottom: 2px solid #f4f7ff;
  gap: 32px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabList::-webkit-scrollbar {
  display: none;
}

.tab {
  line-height: 22px;
  outline: none;
  display: flex;
  padding: 0px 2px 8px 2px;
  color: #63708A;
  white-space: nowrap;
}

.tabSelected {
  color: #3081F6;
  border-bottom: 1px solid #3081F6;
}