@font-face {
  font-family: 'e-Ukraine-Light';
  src: url('assets/fonts/e-Ukraine-Light.eot');
  src: url('assets/fonts/e-Ukraine-Light.woff') format('woff'),
    url('assets/fonts/e-Ukraine-Light.woff2') format('woff2'),
    url('assets/fonts/e-Ukraine-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'e-Ukraine-Regular';
  src: url('assets/fonts/e-Ukraine-Regular.eot');
  src: url('assets/fonts/e-Ukraine-Regular.woff') format('woff'),
    url('assets/fonts/e-Ukraine-Regular.woff2') format('woff2'),
    url('assets/fonts/e-Ukraine-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'e-Ukraine-UltraLight';
  src: url('assets/fonts/e-Ukraine-UltraLight.eot');
  src: url('assets/fonts/e-Ukraine-UltraLight.woff') format('woff'),
    url('assets/fonts/e-Ukraine-UltraLight.woff2') format('woff2'),
    url('assets/fonts/e-Ukraine-UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/e-Ukraine-UltraLight.eot');
  src: url('assets/fonts/e-Ukraine-UltraLight.woff') format('woff'),
    url('assets/fonts/e-Ukraine-UltraLight.woff2') format('woff2'),
    url('assets/fonts/e-Ukraine-UltraLight.ttf') format('truetype');
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  padding: 0;
  margin: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'e-Ukraine-Light', 'Arial';
}

strong {
  font-family: 'e-Ukraine-Regular', 'Arial';
}

.row {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.row:last-of-type,
.row-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3081F6;
  text-decoration: none;
}

h1 {
  padding: 0;
  margin: 0 0 8;
  font-family: 'e-Ukraine-Regular';
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  color: #010E28;
  text-align: center;
}

h2 {
  font-family: 'e-Ukraine-Regular';
  font-style: normal;
  font-size: 24px;
  line-height: 40px;
  color: #010E28;
  text-align: center;
}

.subheading {
  color: #63708A;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Roboto';
  margin-bottom: 40px;
  text-align: center;
}

.subheading>a {
  margin-left: 4px;
}

.right-view {
  padding: 8px;
  width: 344px;
  display: inline-flex;
  justify-content: center;
  max-width: 344;
  flex-direction: column;
}

.container {
  width: 100vw;
  height: 90%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification {
  display: flex;
  flex-grow: 0;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 14px 22px;
  border-radius: 4px;
  margin-bottom: 16p6px;
}

.notification-error {
  background-color: #FFE9E9;
  color: #DA2E30;
}

.notification-info {
  background-color: #F1FFF0;
  color: #00A74D;
}

.small-margin-before {
  margin-top: 8px;
}

/* ---------------------------------- TOKEN CONTAINER ---------------------------------- */

.list-of-tokens {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center {
  align-items: center;
  justify-content: center;
  width: 90%;
}

.tokens-heading {
  display: flex;
  flex-direction: row;
}

.tokens-heading>h2 {
  display: flex;
  flex-grow: 1;
}

.tokens-heading>div {
  display: flex;
}

/* .primary-btn {
  -webkit-appearance: none;
  border-radius: 8px;
  border: none;
  color: white;
  height: 44px;
  width: 176px;
  background: #3081F6;
  font-family: 'e-Ukraine-Light';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
} */

.primary-btn:hover {
  background: #096DFF;
}

.tokens-wrapper {
  justify-content: center;
  align-items: center;
}

.tokens-list {
  display: flex;
  flex-wrap: wrap;
}

.icon-btn {
  stroke: #63708A;
}

.icon-btn:hover * {
  stroke: #096DFF;
}

.logo {
  display: flex;
  margin-left: 4px;
}

.logo-text {
  display: flex;
  margin-left: 16px;
}

.with-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* ---------------------------------- PACKAGE CONTAINER ---------------------------------- */

.list-of-package {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.package-wrapper {
  justify-content: center;
  align-items: center;
}

.packages-list {
  display: flex;
  flex-wrap: wrap;
}

.main-title-wrapper {
  text-align: center;
}

/* Tabs */

.tabs {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #f4f7ff;
}

.tab {
  outline: none;
  display: 'flex';
  margin: '12px';
  padding: 20px 20px 20px 20px;
  color: #63708A;
}

.tab-selected {
  color: #3081F6;
  border-bottom: 1px solid #3081F6;
}

.row {
  display: flex;
  margin-bottom: 24px;
}

.row>strong {
  font-weight: 700;
  color: #63708A;
  margin-right: 8px;
  font-family: 'e-Ukraine-UltraLight', 'Arial';
}

.row>div {
  color: #010E28;
}

.left-icon-absolute {
  position: absolute;
  top: 32px;
  right: 32px;
}

.wanted-item-card {
  /* position: ; */
  position: relative;
  /* background-color: gray; */
}