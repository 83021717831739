.container {
  margin-left: 76px;
  margin-right: 76px;
  margin-bottom: 32px;
  margin-top: 32px;
  padding: 32px;

  border: 2px solid #F5F8FD;
  border-radius: 8px;
}

.wantedContainer {
  margin-left: 76px;
  margin-right: 76px;
  margin-bottom: 32px;
  margin-top: 32px;
  padding: 32px;

  border: 1px solid #DA2E30;
  filter: drop-shadow(0px 0px 10px rgba(124, 142, 168, 0.28));

  border-radius: 8px;
}

.tabList {
  display: flex;
  flex-direction: row;

  margin-top: 56px;
  margin-bottom: 24px;
  margin-left: 76px;
  margin-right: 76px;
}

.tab {
  outline: none;
  display: 'flex';

  font-size: 16px;
  line-height: 28px;
  margin-right: 18px;

  color: #63708A;
}

.tab:last-of-type {
  margin-right: 0px;
}

.tabSelected {
  color: #3081F6;
  font-family: 'e-Ukraine-Regular';
}

@media (max-width: 760px) {
  .container {
    margin: 16px;
  }

  .wantedContainer {
    margin: 16px;
  }

  .tabList {
    margin-left: 16px;
    margin-right: 16px;
  }
}
