.infoBar {
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 24px;
  row-gap: 24px;
}

@media (max-width: 1200px) {
  .infoBar {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 760px) {
  .infoBar {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0px;
    row-gap: 16px;
  }
}

.tabList {
  display: flex;
  margin: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  flex-direction: row;
  border-bottom: 2px solid #f4f7ff;
  gap: 32px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabList::-webkit-scrollbar {
  display: none;
}

.tab {
  line-height: 22px;
  outline: none;
  display: flex;
  padding: 0px 2px 8px 2px;
  color: #63708A;
  white-space: nowrap;
}

.tabSelected {
  color: #3081F6;
  border-bottom: 1px solid #3081F6;
}

.heading {
  color: #3081F6;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  padding-bottom: 16px;
}