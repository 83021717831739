.infoBar {
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 24px;
  row-gap: 24px;
}

@media (max-width: 1200px) {
  .infoBar {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 760px) {
  .infoBar {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0px;
      row-gap: 16px;
  }
}