.container {
  display: flex;
  flex-direction: row;
}

.text {
  display: flex;
  color: inherit;
}

.logo {
  display: flex;
  margin-left: 4px;
}

.icon {
  display: flex;
  margin-left: 4px;
}

.icon * {
  stroke: #63708A;
}

.icon:hover * {
  stroke: #096DFF;
}