.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 64px 140px 64px 140px;
  background-color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(8, 26, 52, 0.04);
  backdrop-filter: blur(3px);
  box-shadow: 0px 0px 40px rgba(134, 159, 209, 0.14);
}

.form {
  margin-top: 40px;
  width: 344px;
}

h1 {
  font-size: 16px;
  line-height: 28px;
  color: #010E28;
}

.containerSuccess {
  width: 420px;
  height: 109px;
}

.note {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #63708A;
}

.title {
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  color: #3081F6;
}