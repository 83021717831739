.footer {
  background-color: #010E28;
  color: white;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 560px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

.container {
  padding: 32px;
}

.title {
  font-size: 16;
  font-family: e-Ukraine-UltraLight;

  color: #FFFFFF;
}

.social {
  margin-top: 12px;
}

.socialIcon {
  margin-right: 12px;
}

.menuItem {
  font-size: 16;
  margin-top: 24px;
}

.menuLink {
  font-family: Roboto;
  font-weight: 200;
  font-size: 16px;
  text-decoration: none;
  color: #63708A;
}

.menuLink:hover {
  color: #3081F6;
  text-decoration: none;
}

.menuLink:focus {
  color: #3081F6;
  text-decoration: none;
}

.logo {
  height: 64px;
  width: 64px;
}

.banner {
  padding: 32px;
  margin-top: 1.0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.font14 {
  font-size: 14px;
  margin: 8px;
}