.row {
  line-height: 26px;
  display: flex;
  margin-bottom: 24px;
}

.row:last-of-type{
  margin-bottom: 0;
}

.row>strong {
  font-weight: 700;
  line-height: 26px;
  color: #63708A;
  margin-right: 8px;
  font-family: 'e-Ukraine-UltraLight', 'Arial';
}

.row>div {
  color: #010E28;
}

@media (max-width: 600px) {
  .row>strong{
    display: flex;
    margin-bottom: 8px;
  }

  .row>div{
    display: flex;
  }

  .row {
    flex-direction: column;
  }
}
