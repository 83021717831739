.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  align-items: stretch;
}

.form-control:last-of-type {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.form-control>input {
  border: 1px solid #C1CDE5;
}

.form-control>input:focus {
  border: 1px solid #3081F6;
}

.form-control>input:hover {
  border: 1px solid #6582BB;
}

.form-control>label {
  margin-bottom: 8px;
  color: #63708A;
  font-size: 12px;
}

.input-success {
  padding: 12px 16px;
  border-radius: 8px;
  height: 18px;
}

.input-error {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #DA2E30;
  height: 18px;
}

.form-control>.error {
  color: #DA2E30;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  padding: 0px;
}

.form-check-control {
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', sans-serif;
  color: #63708A;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

form>input[type=submit] {
  -webkit-appearance: none;
  border-radius: 8px;
  border: none;
  color: white;
  height: 44px;
  background: #3081F6;
  font-family: 'e-Ukraine-Light';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

form>input[type=submit]:hover {
  background: #096DFF;
}

.button {
  -webkit-appearance: none;
  /* color: white; */
  height: 44px;
  padding: 13px;

  border-radius: 8px;
  border: none;

  font-family: 'e-Ukraine-Light';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;

  display: block;
  width: 100%;

  margin-bottom: 24px;
}

.button:last-of-type {
  margin-bottom: 0px;
}

/* Style for primary button */

.primary-button {
  color: white;
  background: #3081F6;
}

.primary-button:hover {
  background: #096DFF;
}

/* Style for google button */

form>.submit-google-button {
  border: 1px solid #C1CDE5;
  color: #3081F6;
  background: #FFFFFF;
}

.submit-google-button:hover {
  border: 1px solid #6582BB;
}