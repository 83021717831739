.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 36px;
  padding: 12px;
}

.title {
  color: #63708A;
  font-size: 10px;
  margin-bottom: 4px;
}

.value {
  color: black;
  font-size: 14px;
}