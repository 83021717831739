.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
  min-width: 400px;

  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.container > h2 {
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 12px;
}

.container > h3 {
  font-size: 16px;
  line-height: 26px;
  color: #63708A;
}

@media (max-width: 760px) {
  .container {
    margin-top: 150px;
  }

  .container > h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .container > h3 {
    font-size: 14px;
    line-height: 20px;
  }
}