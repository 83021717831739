.progressBar {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: white;
}

.progressBarValueA {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear 0s;
  transform-origin: left center;
  background-color: #2ba54a;
  width: 100%;
  animation: 1.8s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running animation-ozg7p2;
}

@-webkit-keyframes animation-ozg7p2 {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes animation-ozg7p2 {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}