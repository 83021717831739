.container {
  flex-basis: calc(33.3% - 32px);
  display: flex;
  flex-flow: column wrap;
  margin: 16px;
  border: 1px solid #C1CDE5;
  box-sizing: border-box;
  border-radius: 8px;
}

@media (max-width: 1800px) {
  .container {
    flex-basis: calc(33.3% - 32px);
  }
}

@media (max-width: 1280px) {
  .container {
    flex-basis: calc(50% - 32px);
  }
}

@media (max-width: 860px) {
  .container {
    flex-basis: calc(100% - 32px);
  }

  .tokens-list {
    justify-content: center;
  }
}

.padding {
  padding: 24px;
}

.row {
  display: flex;
  margin-bottom: 24px;
}

.row>strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #63708A;

  width: 150px;
  margin-right: 32px;
  font-family: 'e-Ukraine-UltraLight', 'Arial';
}

.row>div {
  font-size: 16px;
  line-height: 22px;
  color: #010E28;
  width: 128px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}